




















.cls-1
    fill none
    stroke #000
    stroke-miterlimit 10
    stroke-width 5px
